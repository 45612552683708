@font-face {
    font-family: 'FixedSys';
    src: url('./fonts/fsex300-webfont.woff2') format('woff2'),
         url('./fonts/fsex300-webfont.woff') format('woff'),
         url('./fonts/fsex300.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SystemBold';
    src: url('./fonts/system-bold-webfont.eot');
    src: url('./fonts/system-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/system-bold-webfont.woff2') format('woff2'),
         url('./fonts/system-bold-webfont.woff') format('woff'),
         url('./fonts/system-bold-webfont.ttf') format('truetype'),
         url('./fonts/system-bold-webfont.svg#system_boldregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MSSansSerif';
    src: url('./fonts/ms-sans-serif-webfont.eot');
    src: url('./fonts/ms-sans-serif-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/ms-sans-serif-webfont.woff2') format('woff2'),
         url('./fonts/ms-sans-serif-webfont.woff') format('woff'),
         url('./fonts/ms-sans-serif-webfont.ttf') format('truetype'),
         url('./fonts/ms-sans-serif-webfont.svg#microsoft_sans_serifregular') format('svg');
    font-weight: normal;
    font-style: normal;
}